<template>
    <div class="vx-col w-12/12">
        <div class="vx-row mb-12" style="width:100%">
			<div class="vx-col sm:w-1/3 w-full">
				<span>Contract</span>
			</div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-input class="w-full" v-model="salesOrder.Code" disabled />
            </div>
		</div>
        <div class="vx-row mb-12" style="width:100%">
            <!-- TABLE ACTION ROW -->
            <vs-table :data="data" stripe style="width:100%">
                <template slot="thead">
                    <vs-th>Name</vs-th>
                    <vs-th>Qty</vs-th>
                    <vs-th>Price</vs-th>
                    <vs-th>Subtotal</vs-th>
                    <vs-th>Discount</vs-th>
                    <vs-th>Line Total</vs-th>
                </template>

                <template slot-scope="{data}">
                    <vs-tr :key="index" v-for="(dt, index) in data">
                        <vs-td style="text-align:left">{{dt.ItemName}}</vs-td>
                        <vs-td style="text-align:right">{{dt.Quantity}} ({{dt.ItemUnit}})</vs-td>
                        <vs-td style="text-align:right">{{formatCurrency(dt.Price)}}</vs-td>
                        <vs-td style="text-align:right">{{formatCurrency(dt.Subtotal)}}</vs-td>
                        <vs-td style="text-align:right">{{formatCurrency(dt.Discount)}}</vs-td>
                        <vs-td style="text-align:right">{{formatCurrency(dt.Total)}}</vs-td>
                    </vs-tr>
                </template>
            </vs-table>
        </div>
        <div class="vx-row mb-6" style="width:100%; font-size:10pt;">
            <div class="vx-col sm:w-4/5 w-full" style="text-align:right">
                <span>Total</span>
            </div>
            <div class="vx-col sm:w-1/5 w-full">
                <input class="vs-inputx vs-input--input" style="text-align:right" v-model="totalBilling" disabled />
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: ["selectedContract"],
    created(){
        // console.log("created")
    },
    mounted(){
        this.salesOrder = this.selectedContract;
        this.salesOrderLine = this.salesOrder.ContractItem;
        this.data = this.salesOrder.ContractItem;
        this.loadDatatable();

        this.totalBilling = this.formatCurrency(this.salesOrder.Total); // total bayar = total nett + total pajak + total charge
    },
    watch: {
        selectedSO() {
            this.salesOrder = this.selectedContract;
            this.salesOrderLine = this.salesOrder.ContractItem;
            this.data = this.salesOrder.ContractItem;
            this.loadDatatable();

            this.totalBilling = this.formatCurrency(this.salesOrder.Total); // total bayar = total nett + total pajak + total charge
        },
        search: function() {
            this.filterSOLine();
        }
    },
    computed: {
        currentPageDetail: {
            get() {
                return 1;
            },
            set(page) {
                this.pageDetail(page);
            }
        },
    },
    data: () => ({
        limits: [5,10, 25, 50],
        limitShow: 10,
        drawData: 0,
        isActive: 1,
        checked: [],
        modelCheck: [],
        checkedAll: false,
        search: "",
        detailShow: "core vx-col md:w-1/2 w-full mb-base",
        detailHide: "core vx-col md:w-1/1 w-full mb-base",
        detail: false,
        recordsTotal: 0,
        maxPage: 2,
        dataEnd: 0,
        data: [{}],
        salesOrder: {},
        salesOrderLine: [{}],

        totalBrutto: 0,
        totalDiscount: 0,
        totalNetto: 0,
        totalTax: 0,
        totalCharge: 0,
        totalBilling: 0,
    }),
    methods: {
        pageDetail(page) {
            this.loadDatatable(page);
            return page;
        },
        changeLimit(page) {
            this.limitShow = page;
            this.loadDatatable(this.isActive);
        },
        loadDatatable(page=1) {
            this.isActive = page;

            this.drawData = this.drawData + 1;
            this.recordsTotal = (typeof this.data) != 'undefined' ? this.data.length : 0;
            this.maxPage = Math.ceil(this.recordsTotal / this.limitShow);
            this.dataEnd = (typeof this.data) != 'undefined' ? this.data.length : 0;
        },
        filterSOLine() {
            if(this.search) {
                this.data = this.salesOrderLine.filter(sol => sol.ItemName.indexOf(this.search) > -1) // filter + get 1st record
            } else {
                this.data = this.salesOrderLine
            }
        },
        subtotal(price, qty) {
            let priceNett = 0;
            priceNett = price * qty;
            return priceNett.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        },
        formatCurrency(nominal) {
            if(nominal) {
                return nominal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
            } else {
                return 0;
            }
        },
        formatNumber(string) {
            if(string) {
                return parseFloat(string.toString().replace(/\./g, ""));
            } else {
                return 0;
            }
        }
    }
}

</script>